import React, { useState } from "react";

import { Breadcrumb, Layout, Menu, theme } from "antd";
import { NavLink } from "react-router-dom";
import {
  CreditCardIcon,
  DocumentMagnifyingGlassIcon,
  DocumentChartBarIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";
import {
  CreditCardOutlined,
  FileTextOutlined,
  FileDoneOutlined,
  ProductOutlined,
  AccountBookOutlined,
} from "@ant-design/icons";
const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem(
    <NavLink to="/bank/report">Banks Report</NavLink>,
    "1",
    <FileTextOutlined />
  ),
  getItem(
    <NavLink to="/bank/account-balance">Account Balance</NavLink>,
    "2",
    <AccountBookOutlined />
  ),
  getItem(
    <NavLink to="/customer/report">Customer Report</NavLink>,
    "3",
    <DocumentChartBarIcon />
  ),
  getItem(
    <NavLink to="/customer/summary">Customer Summary</NavLink>,
    "4",
    <DocumentTextIcon />
  ),
  getItem(
    <NavLink to="/customer/receipt/details">Customer Receipt Details</NavLink>,
    "5",
    <CreditCardOutlined />
  ),
  getItem(
    <NavLink to="/supplier/report">Supplier Report</NavLink>,
    "6",
    <DocumentChartBarIcon />
  ),
  getItem(
    <NavLink to="/supplier/summary">Supplier Summary</NavLink>,
    "7",
    <DocumentTextIcon />
  ),
  getItem(
    <NavLink to="/supplier/payment/details">Supplier Payment Details</NavLink>,
    "8",
    <CreditCardIcon />
  ),
  getItem(
    <NavLink to="/products/report">Products Report</NavLink>,
    "9",
    <FileDoneOutlined />
  ),
  getItem(
    <NavLink to="/products/summary">Products Summary</NavLink>,
    "10",
    <ProductOutlined />
  ),
];
const ReportsMenu = () => {
  return (
    <Menu
      id="ant-sidemenu"
      defaultSelectedKeys={["0"]}
      mode="inline"
      items={items}
    />
  );
};
export default ReportsMenu;
