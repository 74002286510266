import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  message,
  Spin,
  Table,
  Empty,
  Checkbox,
  Menu,
  Dropdown,
  Tooltip,
  Divider,
} from "antd";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ProductionMenu from "./ProductionMenu";
import ReportsMenu from "../Reports/ReportsMenu";
import axios from "axios";
import Config from "../../Config";
import SubMenuToggle from "../Common/SubMenuToggle";
import ProductPrint from "../Shared/ProductPrint";
import Logo from "../../assets/images/logo/dark-h.svg";
import dayjs from "dayjs";
import {
  FilterFilled,
  UploadOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import ExcelJS from "exceljs";
import { render } from "@testing-library/react";

const ProductReport = () => {
  const navigate = useNavigate();
  const AccessKey = localStorage.getItem("AccessKey");
  const CompanyID = localStorage.getItem("CompanyID");
  const User = localStorage.getItem("Full_Name");

  const [ProductForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [ProductLoading, setProductLoading] = useState(false);
  const [ListOfRecords, setListOfRecords] = useState([]);
  const [TotalRecords, setTotalRecords] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState(null);
  const [period, setPeriod] = useState("all");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showTable, setShowTable] = useState(false); // New state variable
  const defaultStartDate = dayjs().format("YYYY-MM-DD");
  const defaultEndDate = dayjs().format("YYYY-MM-DD");
  const [listOfProducts, setListOfProducts] = useState([]);
  const [SubCategory, setSubCategory] = useState([]);
  const [newType, setNewType] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(false);

  const fetchProductTransactions = async (FormData) => {
    setLoading(true);
    setError(null);

    const effectiveStartDate =
      FormData.period === "custom" && FormData.startDate
        ? dayjs(FormData.startDate).format("YYYY-MM-DD")
        : defaultStartDate;

    const effectiveEndDate =
      FormData.period === "custom" && FormData.endDate
        ? dayjs(FormData.endDate).format("YYYY-MM-DD")
        : defaultEndDate;

    try {
      const api_config = {
        method: "get",
        url: `${
          Config.base_url
        }Reports/GetProductReportBy/${CompanyID}?productCode=${
          selectedProduct ? selectedProduct.code : 0
        }&startDate=${effectiveStartDate}&endDate=${effectiveEndDate}&period=${
          FormData.period
        }&includeAllProducts=${FormData.product === "all"}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessKey}`,
        },
      };
      const response = await axios(api_config);
      setTransactions(response.data);
      setShowTable(true);
    } catch (err) {
      console.error("Error fetching Product transactions:", err);
      setError("Failed to load transactions.");
    } finally {
      setLoading(false);
    }
  };

  const category = ProductForm.getFieldValue("category");
  const type = ProductForm.getFieldValue("type");

  useEffect(() => {
    document.title = "Product Report";
    fetchProducts();
    GetCategoryDropdownData();
    GetTypeDropdownData();
  }, [category, type]);

  const fetchProducts = async () => {
    setProductLoading(true);
    const api_config = {
      method: "get",
      url: `${
        Config.base_url
      }Product/GetBy/${CompanyID}?&pageNumber=1&pageSize=1000000&category=${
        category || ""
      }&type=${type || ""}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessKey}`,
      },
    };

    try {
      const response = await axios(api_config);
      if (response.data && response.data.status_code === 1) {
        setProductLoading(false);
        setListOfProducts(response.data.listofProducts || []);
      } else {
        setProductLoading(false);
        setListOfProducts([]);
      }
    } catch (error) {
      setProductLoading(false);
      setListOfProducts([]);
    }
  };

  const GetCategoryDropdownData = async () => {
    setCategoryLoading(true);
    try {
      const response = await axios.get(
        Config.base_url +
          `DropdownData/GetDropdownData/${CompanyID}?Type=ProductCategory`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code === 1) {
        setSubCategory(response.data.dropdownData);
        setCategoryLoading(false);
      }
    } catch (error) {
      setCategoryLoading(false);
    }
  };

  const GetTypeDropdownData = async () => {
    try {
      const response = await axios.get(
        Config.base_url +
          `DropdownData/GetDropdownData/${CompanyID}?Type=ProductType`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code === 1) {
        setNewType(response.data.dropdownData);
      }
    } catch (error) {
      //console.error(error);
    }
  };

  const handleProductChange = (value) => {
    if (value === "all") {
      setSelectedProduct(null);
      ProductForm.setFieldsValue({
        code: null,
      });
    } else {
      const product = ListOfRecords.find((product) => product.name === value);
      setSelectedProduct(product);
      if (product) {
        ProductForm.setFieldsValue({
          product: product.name,
        });
      }
    }
  };

  const handleDateChange = (date, dateString) => {
    setStartDate(date ? date.format("YYYY-MM-DD") : null);
  };

  const handleDueDateChange = (date, dateString) => {
    setEndDate(date ? date.format("YYYY-MM-DD") : null);
  };

  const handleExport = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Product Report");

    const productName = selectedProduct
      ? `${selectedProduct.name} (${selectedProduct.code})`
      : "All Products";

    sheet.mergeCells("A1:M2"); // Merge cells for the title
    const titleCell = sheet.getCell("C3:G3");
    titleCell.value = `Product Report: ${productName}`;
    titleCell.font = { bold: true, size: 18 };
    titleCell.alignment = { vertical: "middle", horizontal: "center" };

    sheet.columns = [
      { header: "Date", key: "date", width: 15 },
      { header: "Supplier Name", key: "supplierName", width: 30 },
      { header: "Customer Name", key: "customerName", width: 20 },
      { header: "Purchase Quantity", key: "purchaseQuantity", width: 20 },
      { header: "Purchase Weight", key: "purchaseWeight", width: 20 },
      { header: "Purchase Length", key: "purchaseLength", width: 20 },
      { header: "Sale Quantity", key: "saleQuantity", width: 20 },
      { header: "Sale Weight", key: "saleWeight", width: 20 },
      { header: "Sale Length", key: "saleLength", width: 20 },
      { header: "Default Unit", key: "defaultUnit", width: 20 },
      { header: "Balance Quantity", key: "balanceQuantity", width: 20 },
      { header: "Balance Weight", key: "balanceWeight", width: 20 },
      { header: "Balance Length", key: "balanceLength", width: 20 },
    ];

    const headerRow = sheet.addRow([
      "Date",
      "Supplier Name",
      "Customer Name",
      "Purchase Quantity",
      "Purchase Weight",
      "Purchase Length",
      "Sale Quantity",
      "Sale Weight",
      "Sale Length",
      "Default Unit",
      "Balance Quantity",
      "Balance Weight",
      "Balance Length",
    ]);

    // Apply styling to the header row
    headerRow.font = { bold: true, color: { argb: "FFFFFFFF" } }; // White text color
    headerRow.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF324F94" }, // Background color #324F94 (blue)
    };

    let purQty = 0;
    let purWeight = 0;
    let purLength = 0;
    let saleQty = 0;
    let saleWeight = 0;
    let saleLength = 0;
    let totalQty = 0;
    let totalLen = 0;
    let totalWeight = 0;

    // Add rows to the sheet
    transactions.forEach((product, index) => {
      purQty += product.purchaseQuantity;
      purWeight += product.purchaseWeight;
      purLength += product.purchaseLength;
      saleQty += product.saleQuantity;
      saleWeight += product.saleWeight;
      saleLength += product.saleLength;
      totalLen += product.balanceLength;
      totalWeight += product.balanceWeight;
      totalQty += product.balanceQuantity;

      sheet.addRow({
        sr: index + 1,
        date: dayjs(product.date).format("YYYY-MM-DD"),
        supplierName: product.supplierName,
        customerName: product.customerName,
        purchaseQuantity: product.purchaseQuantity,
        purchaseWeight: product.purchaseWeight,
        purchaseLength: product.purchaseLength,
        saleQuantity: product.saleQuantity,
        saleWeight: product.saleWeight,
        saleLength: product.saleLength,
        defaultUnit: product.defaultUnit,
        balanceQuantity: product.balanceQuantity,
        balanceWeight: product.balanceWeight,
        balanceLength: product.balanceLength,
      });
    });

    const totalsRow = sheet.addRow({
      date: "Totals",
      purchaseQuantity: purQty.toFixed(2),
      purchaseWeight: purWeight.toFixed(2),
      purchaseLength: purLength.toFixed(2),
      saleQuantity: saleQty.toFixed(2),
      saleWeight: saleWeight.toFixed(2),
      saleLength: saleLength.toFixed(2),
      balanceQuantity: totalQty.toFixed(2),
      balanceWeight: totalWeight.toFixed(2),
      balanceLength: totalLen.toFixed(2),
    });

    // Apply styling to the totals row
    totalsRow.eachCell((cell, colNumber) => {
      cell.font = { bold: true };
      cell.alignment = { horizontal: "right" };
      if (colNumber > 1) {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
    });

    const now = new Date();
    const dateString = now
      .toLocaleString("sv-SE", { timeZoneName: "short" }) // Format: YYYY-MM-DD HH:mm:ss
      .replace(/[^0-9]/g, ""); // Remove special characters like : and space

    // Generate the Excel file and prompt the user to download it
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `ProductReport_${dateString}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  let runningQuantity = 0;
  let runningWeight = 0;
  let runningLength = 0;

  const columns = [
    // {
    //   title: "Sr#",
    //   dataIndex: "",
    //   key: "SR",
    //   width: 30,
    //   render: (_, record, index) => index + 1,
    // },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 30,
      render: (date) => dayjs(date).format("YYYY-MM-DD"),
      sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      sorter: (a, b) => String(a.details).localeCompare(String(b.details)),
    },
    {
      title: "V. No",
      dataIndex: "voucherNo",
      key: "voucherNo",
      sorter: (a, b) => a.voucherNo - b.voucherNo,
    },
    {
      title: "In Quantity",
      dataIndex: "inQuantity",
      key: "inQuantity",
      sorter: (a, b) => a.inQuantity - b.inQuantity,
    },
    {
      title: "In Weight",
      dataIndex: "inWeight",
      key: "inWeight",
      sorter: (a, b) => a.inWeight - b.inWeight,
    },
    {
      title: "In Length",
      dataIndex: "inLength",
      key: "inLength",
      sorter: (a, b) => a.inLength - b.inLength,
    },
    {
      title: "Out Quantity",
      dataIndex: "outQuantity",
      key: "outQuantity",
      sorter: (a, b) => a.outQuantity - b.outQuantity,
    },
    {
      title: "Out Weight",
      dataIndex: "outWeight",
      key: "outWeight",
      sorter: (a, b) => a.outWeight - b.outWeight,
    },
    {
      title: "Out Length",
      dataIndex: "outLength",
      key: "outLength",
      sorter: (a, b) => a.outLength - b.outLength,
    },
    {
      title: "Default Unit",
      dataIndex: "defaultUnit",
      key: "defaultUnit",
      sorter: (a, b) =>
        String(a.defaultUnit).localeCompare(String(b.defaultUnit)),
    },
    {
      title: "Balance Quantity",
      dataIndex: "",
      key: "balanceQuantity",
      sorter: (a, b) => a.balanceQuantity - b.balanceQuantity,
      render: (_, record, index) => {
        if (record.defaultUnit === "Quantity") {
          const currentQuantity = record.inQuantity - record.outQuantity;

          if (index === 0) {
            runningQuantity = currentQuantity;
          } else {
            runningQuantity += currentQuantity;
          }
        }
        return runningQuantity.toFixed(2);
      },
    },
    {
      title: "Balance Weight",
      dataIndex: "",
      key: "balanceWeight",
      sorter: (a, b) => a.balanceWeight - b.balanceWeight,
      render: (_, record, index) => {
        if (record.defaultUnit === "Weight") {
          const currentWeight = record.inWeight - record.outWeight;

          if (index === 0) {
            runningWeight = currentWeight;
          } else {
            runningWeight += currentWeight;
          }
        }
        return runningWeight.toFixed(2);
      },
    },
    {
      title: "Balance Length",
      dataIndex: "",
      key: "balanceLength",
      sorter: (a, b) => a.balanceLength - b.balanceLength,
      render: (_, record, index) => {
        if (record.defaultUnit === "Length") {
          const currentLength = record.inLength - record.outLength;

          if (index === 0) {
            runningLength = currentLength;
          } else {
            runningLength += currentLength;
          }
        }
        return runningLength.toFixed(2);
      },
    },
  ];

  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );

  const columnsToShow = columns.filter((col) =>
    selectedColumns.includes(col.key)
  );

  const menu = (
    <Menu>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "10px",
          padding: "10px",
        }}
      >
        {columns.map((col) => (
          <div key={col.key} style={{ display: "flex", alignItems: "start" }}>
            <Checkbox
              checked={selectedColumns.includes(col.key)}
              onChange={(e) => {
                const newSelected = e.target.checked
                  ? [...selectedColumns, col.key]
                  : selectedColumns.filter((c) => c !== col.key);
                setSelectedColumns(newSelected);
              }}
            >
              {col.title}
            </Checkbox>
          </div>
        ))}
      </div>
    </Menu>
  );

  const handleSelectChange = (value) => {
    //console.log(value);
    const product = listOfProducts.find((product) => product.code === value);
    setSelectedProduct(product);
  };

  return (
    <>
      <div id="sub-menu-wrap">
        <h5>Reports</h5>
        <ReportsMenu />
      </div>

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <SubMenuToggle />
            <h3 className="page-title">
              <NavLink to="/products/manage">
                <ArrowLeftIcon />
              </NavLink>
              Product Report
            </h3>
            {showTable && transactions.length > 0 && (
              <div className="header-actions">
                <Button
                  type="dashed"
                  onClick={handleExport}
                  icon={<UploadOutlined />}
                >
                  Export Report
                </Button>
                <ProductPrint
                  selectedSupplier={selectedProduct}
                  startDate={startDate}
                  endDate={endDate}
                  User={User}
                  title="Product Report"
                />
              </div>
            )}
          </div>
          <div className="filters-wrap">
            <Form
              onFinish={fetchProductTransactions}
              form={ProductForm}
              initialValues={{
                category: "",
                type: "",
              }}
            >
              <Form.Item
                name="product"
                rules={[
                  {
                    required: true,
                    message: "Please select a product",
                  },
                ]}
              >
                <Select
                  style={{ width: "300px" }}
                  placeholder="Select Product"
                  onSelect={handleSelectChange}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <Select.Option value="all">All Products</Select.Option>
                  {listOfProducts.map((product) => (
                    <Select.Option key={product.id} value={product.code}>
                      {product.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="category">
                <Select
                  style={{ width: 150 }}
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder="Category"
                  options={[
                    { label: "All Categories", value: "" },
                    ...SubCategory.map((item) => ({
                      label: item.name,
                      value: item.name,
                    })),
                  ]}
                  onSelect={fetchProducts}
                />
              </Form.Item>

              <Form.Item name="type">
                <Select
                  style={{ width: 150 }}
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder="Type"
                  options={[
                    { label: "All Types", value: "" },
                    ...newType.map((item) => ({
                      label: item.name,
                      value: item.name,
                    })),
                  ]}
                  onSelect={fetchProducts}
                />
              </Form.Item>

              <Form.Item name="code" label="Code" hidden>
                <Input />
              </Form.Item>
              <Form.Item
                name="period"
                onChange={(value) => setPeriod(value)}
                style={{ width: "150px" }}
                initialValue="year"
              >
                <Select placeholder="Date Range" onChange={setPeriod}>
                  <Select.Option value="all">All Dates</Select.Option>
                  <Select.Option value="custom">Custom</Select.Option>
                  <Select.Option value="today">Today</Select.Option>
                  <Select.Option value="week">This Week</Select.Option>
                  <Select.Option value="month">This Month</Select.Option>
                  <Select.Option value="last60Days">Last 60 Days</Select.Option>
                  <Select.Option value="year">This Year</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="startDate"
                dependencies={["period"]}
                hidden={period !== "custom"}
              >
                <DatePicker
                  value={startDate ? dayjs(startDate, "YYYY-MM-DD") : null}
                  onChange={handleDateChange}
                />
              </Form.Item>
              <Form.Item
                name="endDate"
                dependencies={["period"]}
                hidden={period !== "custom"}
              >
                <DatePicker
                  value={endDate ? dayjs(endDate, "YYYY-MM-DD") : null}
                  onChange={handleDueDateChange}
                />
              </Form.Item>

              <Button type="primary" htmlType="submit" loading={loading}>
                Run Report
              </Button>
            </Form>
          </div>

          {showTable && transactions.length > 0 && (
            <>
              <div className="filter-dropdown">
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  placement="top"
                  overlayStyle={{ maxHeight: "300px", overflowY: "auto" }}
                >
                  <span>
                    <Tooltip>
                      <FilterFilled
                        style={{
                          fontSize: "24px",
                          color: "#1890ff",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "flex-end",
                          color: "#46484B",
                          marginBottom: "10px",
                        }}
                      />
                    </Tooltip>
                  </span>
                </Dropdown>
              </div>
              <div className="reports-main-div">
                {/* Main Content */}

                <div className="report-content">
                  {/* Left: Account Code and Opening Balance */}
                  <div className="report-left">
                    <h2>
                      {selectedProduct ? selectedProduct.name : "All Products"}
                    </h2>
                    {selectedProduct && (
                      <>
                        <h3>Product Code: {selectedProduct.code}</h3>
                      </>
                    )}
                  </div>
                  <img className="report-company-name" src={Logo} />
                  {/* Right: User Name and Printed Date */}
                  <div className="report-right">
                    <h2>Product Report</h2>
                    <h3>Printed by: {User} </h3>
                    <h3>Printed on: {new Date().toLocaleString()}</h3>
                  </div>
                </div>
              </div>

              <Table
                columns={columnsToShow}
                dataSource={transactions}
                rowKey={(record) => record.id || Math.random()}
                pagination={false}
              />
            </>
          )}

          {showTable && transactions.length === 0 && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}

          {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
      </div>
    </>
  );
};

export default ProductReport;
