import React, { useEffect, useState } from "react";

import Logo from "../../assets/images/logo/white-v.svg";
import { NavLink } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";

import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Badge, Button, Skeleton, Spin, message } from "antd";
import Config from "../../Config";
import SubMenuToggle from "../Common/SubMenuToggle";
import axios from "axios";
import BadgeComponent from "../Common/Badge";

function SelectCompany(props) {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [ListOfCompanies, setListOfCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [PendingInvites, setPendingInvites] = useState(0);

  const navigate = useNavigate();

  const handleSignOut = () => {
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    const fetchRoles = async () => {
      setLoading(true);
      try {
        const response = await axios.get(Config.base_url + `Account/GetCompaniesByUserID/${UserID}`, {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        });
        // console.log(response.data)
        if (response.data.status_code == 1) {
          // Extract company info
          const extractCompanyInfo = (data) => {
            return data.map((item) => item.companyInfo);
          };
          //console.log(response)
          const list = extractCompanyInfo(response.data.listofRecords) || [];
          setPendingInvites(response.data.pendingInvites);
          if (list.length > 0) {
            setLoading(false);
            if (list.length > 1) {
              setListOfCompanies(list);
            } else {
              const first = list[0];
              localStorage.setItem("CompanyCode", first.companyCode);
              localStorage.setItem("CompanyID", first.id);
              localStorage.setItem("CompanyName", first.name);
              localStorage.setItem("CompanyAddress", first.address);
              navigate("/dashboard");
            }
          } else {
            setLoading(false);
            navigate("/company-register");
          }
        }
        else {
          // message.error(response.data.status_message);
          setLoading(false);
          navigate("/company-register");
        }
      } catch (error) {
        setListOfCompanies([]);
        setLoading(false);
        // //console.log(error)
        message.error("Network Error...")
      }
    };

    fetchRoles();
  }, [UserID, AccessKey, navigate]);


  const handleCompany = (item) => {
    localStorage.setItem("CompanyCode", item.companyCode);
    localStorage.setItem("CompanyID", item.id);
    localStorage.setItem("CompanyName", item.name);
    localStorage.setItem("CompanyAddress", item.address);
    navigate("/dashboard");
  };

  return (
    <div className="auth-wrap">
      <div className="left-col">
        <div>
          <img src={Logo} />
          <h3>
            Streamline Your Finances: Welcome to Effortless Invoicing &
            Accounting!
          </h3>
        </div>
      </div>
      <div className="right-col">
        <div className="header" >
          <NavLink to={props.link} style={{ marginRight: "20px" }}>

            <Badge count={PendingInvites}>

              <Button type="dashed">
                Pending Invites
              </Button>
            </Badge>
          </NavLink>
          <Button onClick={handleSignOut}>Sign Out</Button>
        </div>
        <div className="auth-form-wrap">
          <div className="section-title" style={{ marginBottom: "50px" }}>
            <h2>Welcome to Hisaaber</h2>
            <p>
              Get Ready to Experience Effortless Invoicing and Streamlined
              Accounting
            </p>
          </div>

          {loading ? (
            <Skeleton active />
          ) : (
            <ul className="list-actions">
              {ListOfCompanies?.map((item, index) => (
                <li key={index}>
                  <a href="#" onClick={(e) => handleCompany(item)}>
                    <span>{item.name}</span>
                    <ChevronRightIcon />
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default SelectCompany;
