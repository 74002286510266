import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  message,
  Spin,
  Table,
  Empty,
  Flex,
} from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Config from "../../Config";
import SubMenuToggle from "../Common/SubMenuToggle";
import dayjs from "dayjs";
import PrintTable from "../Shared/PrintTable";
import Logo from "../../assets/images/logo/dark-h.svg";
import ReportsMenu from "../Reports/ReportsMenu";
import SuppliersDropdown from "../Shared/SuppliersDropdown";

const SupplierPaymentsDetail = () => {
  const navigate = useNavigate();
  const AccessKey = localStorage.getItem("AccessKey");
  const CompanyID = localStorage.getItem("CompanyID");
  const User = localStorage.getItem("Full_Name");

  const [SupplierForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [SupplierLoading, setSupplierLoading] = useState(false);
  const [ListOfRecords, setListOfRecords] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [error, setError] = useState(null);
  const [period, setPeriod] = useState("all");
  const [supplierAccountCode, setSupplierAccountCode] = useState(null);
  const [PaymentBodies, setPaymentBodies] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [includeAllSuppliers, setIncludeAllSuppliers] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const defaultStartDate = dayjs().format("YYYY-MM-DD");
  const defaultEndDate = dayjs().format("YYYY-MM-DD");

  const fetchPayments = async (FormData) => {
    setLoading(true);

    const effectiveStartDate =
      FormData.period === "custom" && FormData.startDate
        ? dayjs(FormData.startDate).format("YYYY-MM-DD")
        : defaultStartDate;

    const effectiveEndDate =
      FormData.period === "custom" && FormData.endDate
        ? dayjs(FormData.endDate).format("YYYY-MM-DD")
        : defaultEndDate;

    try {
      const api_config = {
        method: "get",
        url: `${Config.base_url}Reports/GetPaymentTransactions/${CompanyID}?supplierAccountCode=${supplierAccountCode}&startDate=${effectiveStartDate}&endDate=${effectiveEndDate}&period=${FormData.period}&includeAllSuppliers=${includeAllSuppliers}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessKey}`,
        },
      };

      const response = await axios(api_config);
      //console.log(response.data);
      if (response.data.statusCode === 1) {
        // Transform the data
        const transformData = (Payments) => {
          return Payments.map((payment) => ({
            key: payment.voucherNo,
            id: payment.id,
            voucherNo: payment.voucherNo,
            date: payment.date,
            supplierName: payment.supplierName,
            purchaseType: payment.purchaseType,
            amount: payment.amount,
            total: payment.total,
            children: payment.paymentBodies
              ? payment.paymentBodies.map((body) => ({
                  key: body.billID,
                  id: body.id,
                  voucherNo: body.voucherNo,
                  createdDate: dayjs(body.createdDate).format("YYYY-MM-DD"),
                  billID: body.billID,
                  purchaseType: body.purchaseType,
                  amount: body.amount,
                  total: body.total,
                }))
              : undefined,
          }));
        };
        // Update the state with transformed data
        setPaymentBodies(transformData(response.data.headTransactions));
        setShowTable(true);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Supplier Payment Details";
    fetchSuppliers();
  }, []);

  const fetchSuppliers = async () => {
    setSupplierLoading(true);
    try {
      const response = await SuppliersDropdown();
      if (response != null) {
        setListOfRecords(response);
      }
    } catch (error) {
      // console.error(error);
    } finally {
      setSupplierLoading(false);
    }
  };
  const supplier = [
    {
      businessName: "All Suppliers",
      accountCode: "0000",
      accountNo: "0000",
      id: 0,
      isCustomer: true,
      isSupplier: false,
    },
    ...ListOfRecords,
  ].map(
    ({ businessName, accountCode, id, isCustomer, isSupplier, accountNo }) => {
      const trimmedBusinessName = businessName.trim();
      const parsedAccountCode = parseInt(accountCode);
      let accountLabel = accountNo;

      if (isSupplier && parsedAccountCode < 9000) {
        accountLabel += " (S)";
      } else if (isCustomer && parsedAccountCode > 9000) {
        accountLabel += " (C)";
      }

      return {
        label: `${trimmedBusinessName} (${accountLabel})`.trim(),
        value: id,
      };
    }
  );

  const handleSelectChange = async (value) => {
    if (value === 0) {
      setSelectedSupplier(null);
      setSupplierAccountCode("all");
      setIncludeAllSuppliers(true);
      return;
    }
    const selectedSupplier = ListOfRecords.find(
      (supplier) => supplier.id == value
    );
    if (selectedSupplier) {
      setIncludeAllSuppliers(false);
      setSelectedSupplier(ListOfRecords.find((record) => record.id === value));
      setSupplierAccountCode(selectedSupplier.accountNo);
    }
  };

  const handleDateChange = (date) => {
    setStartDate(date ? date.format("YYYY-MM-DD") : null);
  };

  const handleDueDateChange = (date) => {
    setEndDate(date ? date.format("YYYY-MM-DD") : null);
  };

  const handleExpandAll = () => {
    const allKeys = PaymentBodies.map((item) => item.id);
    setExpandedKeys(allKeys);
  };

  const handleCollapseAll = () => {
    setExpandedKeys([]);
  };

  const columns = [
    {
      title: "Voucher No",
      dataIndex: "voucherNo",
      key: "voucherNo",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "200px",
    },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      width: "300px",
    },
    {
      title: "Type",
      dataIndex: "purchaseType",
      key: "purchaseType",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (value) => (
        <div style={{ textAlign: "right" }}>
          {value ? value.toFixed(2) : "0.00"}
        </div>
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (value) => (
        <div style={{ textAlign: "right" }}>
          {value ? value.toFixed(2) : "0.00"}
        </div>
      ),
    },

    {
      title: "Expand",
      key: "expand",
      dataIndex: "id",
      render: () => null,
      colSpan: 0,
      fixed: true,
    },
  ];

  const expandedRowColumns = [
    {
      title: "Bill ID",
      dataIndex: "billID",
      key: "billID",
    },

    {
      title: "Date",
      dataIndex: "createdDate",
      key: "createdDate",
    },
    {
      title: "Type",
      dataIndex: "purchaseType",
      key: "purchaseType",
    },
    {
      title: "Voucher No",
      dataIndex: "voucherNo",
      key: "voucherNo",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (value) => (
        <div style={{ textAlign: "right" }}>
          {value ? value.toFixed(2) : "0.00"}
        </div>
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (value) => (
        <div style={{ textAlign: "right" }}>
          {value ? value.toFixed(2) : "0.00"}
        </div>
      ),
    },
  ];

  return (
    <>
      <div id="sub-menu-wrap">
        <h5>Reports</h5>
        <ReportsMenu />
      </div>

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <SubMenuToggle />
            <h3 className="page-title">Supplier Payment Details</h3>
            {showTable && PaymentBodies.length > 0 && (
              <div className="header-actions">
                <PrintTable
                  selectedSupplier={selectedSupplier}
                  startDate={startDate}
                  endDate={endDate}
                  User={User}
                  title="Supplier Payment Details"
                />
              </div>
            )}
          </div>

          <div className="filters-wrap">
            <Form onFinish={fetchPayments} form={SupplierForm}>
              <Form.Item name="supplierName">
                <Select
                  style={{ width: "300px" }}
                  placeholder="Select Supplier"
                  showSearch
                  optionFilterProp="label" // Specifies which prop should be filtered (label)
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase().includes(input.toLowerCase())
                  }
                  loading={SupplierLoading}
                  notFoundContent={
                    SupplierLoading ? <Spin size="small" /> : null
                  }
                  options={supplier}
                  onSelect={handleSelectChange}
                />
              </Form.Item>

              <Form.Item
                name="supplierAccountCode"
                label="Supplier Account Code"
                hidden
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="period"
                style={{ width: "150px" }}
                initialValue="year"
              >
                <Select placeholder="Date Range" onChange={setPeriod}>
                  <Select.Option value="all">All Dates</Select.Option>
                  <Select.Option value="custom">Custom</Select.Option>
                  <Select.Option value="today">Today</Select.Option>
                  <Select.Option value="week">This Week</Select.Option>
                  <Select.Option value="month">This Month</Select.Option>
                  <Select.Option value="last60Days">Last 60 Days</Select.Option>
                  <Select.Option value="year">This Year</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="startDate"
                dependencies={["period"]}
                hidden={period !== "custom"}
              >
                <DatePicker
                  value={startDate ? dayjs(startDate, "YYYY-MM-DD") : null}
                  onChange={handleDateChange}
                />
              </Form.Item>
              <Form.Item
                name="endDate"
                dependencies={["period"]}
                hidden={period !== "custom"}
              >
                <DatePicker
                  value={endDate ? dayjs(endDate, "YYYY-MM-DD") : null}
                  onChange={handleDueDateChange}
                />
              </Form.Item>

              <Button type="primary" htmlType="submit" loading={loading}>
                Run Report
              </Button>
            </Form>
          </div>

          {showTable && PaymentBodies.length > 0 && (
            <>
              <div className="reports-main-div">
                <div className="report-content">
                  <div className="report-left">
                    <h2>
                      {selectedSupplier
                        ? selectedSupplier.businessName
                        : "All Suppliers"}
                    </h2>
                    {selectedSupplier && (
                      <>
                        <h3>Account Code: {selectedSupplier.accountNo}</h3>
                      </>
                    )}
                  </div>
                  <img className="report-company-name" src={Logo} />
                  <div className="report-right">
                    <h2>Supplier Payment Details</h2>
                    <h3>Printed by: {User} </h3>
                    <h3>Printed on: {new Date().toLocaleString()}</h3>
                  </div>
                </div>
              </div>

              <Spin spinning={loading}>
                <div style={{ marginBottom: 15, textAlign: "right" }}>
                  <Button onClick={handleExpandAll} style={{ marginRight: 15 }}>
                    Expand All
                  </Button>
                  <Button onClick={handleCollapseAll}>Collapse All</Button>
                </div>
                <Table
                  columns={columns}
                  dataSource={PaymentBodies}
                  rowKey="id"
                  expandable={{
                    expandedRowRender: (record) => (
                      <Table
                        columns={expandedRowColumns}
                        dataSource={record.children}
                        pagination={false}
                      />
                    ),
                    rowExpandable: (record) =>
                      record.children && record.children.length > 0,
                    expandedRowKeys: expandedKeys,
                    onExpand: (expanded, record) => {
                      setExpandedKeys((prevKeys) =>
                        expanded
                          ? [...prevKeys, record.id]
                          : prevKeys.filter((key) => key !== record.id)
                      );
                    },
                  }}
                  pagination={{ pageSize: 20 }}
                  rowClassName="outer-row"
                />
              </Spin>
            </>
          )}

          {showTable && PaymentBodies.length === 0 && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}

          {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
      </div>
    </>
  );
};

export default SupplierPaymentsDetail;
